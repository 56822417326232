@import '~@Styles/vars';

.container {
  grid-template-rows: max-content auto;

  .leftSideContainer {
    grid-column: col-start 1 / col-end 15;
  }

  .topContainer {
    display: flex;

    .pageTitle {
      padding: 28px 0 38px;
    }
  }

  .listContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 28px;
    padding: 0 0 40px;
  }

  .filterContainer {
    grid-column: col-start 16 / margin-right-end;
    border-left: 1px solid $darkPaleBlue;

    .showsFilter {
      border: 0;
      border-bottom: 1px solid $darkPaleBlue;
      position: sticky;
      top: 0;
    }
  }
}

.navigationFields {
  display: grid;
  grid-column: details;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: max-content max-content;
}
