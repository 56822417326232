@import '~@Styles/vars';

.container {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: $zindex-positive;

  &.animate {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 400%;
      animation: loadingAnimation 2s linear infinite;
      background: linear-gradient(to right, rgba($white, 0) 0%, rgba($white, 0.1) 40%, rgba($white, 0) 80%);
    }
  }

  @keyframes loadingAnimation {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }

  &.color-darkPaleBlue {
    background: $darkPaleBlue;

    &::before {
      background: linear-gradient(to right, rgba($white, 0) 0%, rgba($white, 0.1) 40%, rgba($white, 0) 80%);
    }
  }

  &.color-dustyDarkBlue {
    background: $dustyDarkBlue;

    &::before {
      background: linear-gradient(to right, rgba($white, 0) 0%, rgba($white, 0.1) 40%, rgba($white, 0) 80%);
    }
  }

  &.color-lightGrey {
    background: $lightGrey;

    &::before {
      background: linear-gradient(to right, rgba($black, 0) 0%, rgba($black, 0.1) 40%, rgba($black, 0) 80%);
    }
  }

  &.color-veryLightGrey {
    background: $veryLightGrey;

    &::before {
      background: linear-gradient(to right, rgba($darkGrey, 0) 0%, rgba($darkGrey, 0.1) 40%, rgba($darkGrey, 0) 80%);
    }
  }

  &.type-Circle {
    border-radius: $borderRadiusCircle;
  }

  &.type-Rectangle {
    border-radius: $noBorderRadius;
  }

}
