@import '~@Styles/vars';

.container {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-column-gap: 10px;
  justify-content: space-between;
  height: 60px;
  align-items: center;
  padding: 15px 23px 15px 15px;
  width: 100%;
  border-bottom: 1px solid $fadedBlue;
}

.divider {
  border-left: 1px solid $lightGrey;
  height: 20px;
}

.backgroundDropdownContent {
  width: 400px;
  padding: 20px;
}

.dropdownCaret {
  margin-left: 4px;
}

.fontIconsContainer {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-column-gap: 5px;
  align-items: center;
}

.fontIcon {
  cursor: pointer;
  padding: 6px;
  border-radius: $xsBorderRadius;
  transition: 0.3s background-color;

  &:hover {
    background: $darkPaleBlue;
  }

  &.activeIcon {
    background-color: $darkGrey;
  }
}

.colorIndicator {
  border: 1px solid $white;
  border-radius: $xsBorderRadius;
  width: 18px;
  height: 18px;
  background-color: transparent;
  margin-right: 10px;
  position: relative;
}

.orderIconsContainer,
.alignIconsContainer {
  display: grid;
  row-gap: 5px;
  column-gap: 5px;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding: 10px 0;
}

.alignIcon {
  path,
  rect {
    stroke: $darkPaleBlue;
  }
}

.orderIcon {
  path {
    fill: $darkPaleBlue;
  }
  line {
    stroke: $darkPaleBlue;
  }
}

.leftSide {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-column-gap: 10px;
  align-items: center;
}

.rightSide {
  display: flex;
}

.changeImageButton {
  background: transparent;
  color: $white;
  border: 0;
}

.saveButton {
  padding-top: 3px;
  padding-bottom: 3px;
  height: 28px;
  margin-left: 20px;
  box-shadow: none !important;
}

.clickableItem {
  cursor: pointer;
  padding: 6px;
  border-radius: $xsBorderRadius;
  transition: 300ms background-color;

  &:hover {
    background: $darkPaleBlue;
  }
}

.positionDropdownClickableItem {
  cursor: pointer;
  padding: 6px;
  border-radius: $xsBorderRadius;
  transition: 300ms background-color;
  display: grid;
  grid-template-columns: max-content max-content;
  column-gap: 8px;
  align-items: center;

  &:hover {
    background: $veryLightGrey;
  }
}

.positionDropdown {
  padding: 10px 20px;
}

.fontSizeInput {
  width: 50px;
  height: 20px;
  text-align: center;
  padding: 3px;
}
