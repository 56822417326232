@import '~@Styles/vars';
@import '~@Styles/mixins';

.container {
  display: grid;
  grid-row-gap: 4px;
  cursor: pointer;

  .labelContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .label {
      color: $lightFadedBlue;
    }
  }


  :global {
    .FilterSelect__control {
      background: inherit;
      border: none;
      cursor: pointer;
      min-height: unset;

      &--is-focused {
        border: none;
        outline: none;
        box-shadow: none;
      }
    }

    .FilterSelect__value-container {
      padding: 0;
      height: 20px;
      display: flex;
      flex-wrap: unset;
    }

    .FilterSelect__single-value {
      color: $white;
      margin: 0;
      position: unset;
      transform: unset;
      top: unset;
    }

    .FilterSelect__menu {
      border-radius: $xsBorderRadius;
    }

    .FilterSelect__menu-list {
      display: flex;
      flex-direction: column;
      padding: 20px;
      max-height: $dropDownMaxHeight;
      @include scrollbar($lightGrey, $white);
    }
  }

  .option {
    color: $black;
    background-color: $white;
    display: grid;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 12px;
    grid-auto-flow: column;
    grid-column-gap: 4px;

    &:last-of-type {
      padding-bottom: 0;
    }

    &:hover {
      cursor: pointer;
      background-color: $white;

      .optionText {
        color: $kioskPurple;
        font-weight: $bold;
        line-height: $lowerCaseLinkLineHeight;
        text-transform: none;
        letter-spacing: 0;
      }
    }

    &--is-selected {
      background-color: $white;
      color: $kioskPurple
    }

    .selectedText {
      color: $kioskPurple;
    }

    .icon {
      path {
        fill: $kioskPurple;
      }
    }
  }
}
