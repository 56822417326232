@import '~@Styles/vars';

.container {
  display: grid;
  justify-content: center;
  grid-auto-flow: column;
  grid-column: links;
  grid-gap: 20px;
  height: 100%;

  @media (max-width: 768px) {
    grid-gap: unset;
    grid-area: links;
    justify-content: space-around;
  }

  .item {
    height: 100%;
    align-items: center;
    display: flex;
    padding: 10px 30px;

    @media (max-width: 768px) {
      padding: 10px 15px;
    }

    &.screens {
      &:hover {
        color: $kioskPurple;
      }

      &.active {
        color: $kioskPurple;
      }
    }

    &.channels {
      &:hover {
        color: $kioskGreen;
      }

      &.active {
        color: $kioskGreen;
      }
    }

    &.shows {
      &:hover {
        color: $kioskBlue;
      }

      &.active {
        color: $kioskBlue;
      }
    }

    .firstLetterBorder {
      width: 10px;
      height: 0;
      position: relative;
      bottom: -4px;
    }

    .screensFirstLetter {
      border-top: 1px solid $kioskPurple;
    }

    .showsFirstLetter {
      border-top: 1px solid $kioskBlue;
    }

    .channelsFirstLetter {
      border-top: 1px solid $kioskGreen;
    }
  }
}
