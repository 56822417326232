.container {
  display: grid;
  grid-row-gap: 40px;
  grid-column: 1/-1;
  grid-auto-rows: max-content;
  justify-content: center;
  align-content: center;

  .textContainer {
    display: grid;
    grid-row-gap: 16px;
    justify-items: center;
  }

  .loaderIcon {
    width: 75px;
    height: 75px;
  }
}
