@import '~@Styles/vars';

$colors: (
  'green': $kioskGreen,
  'blue': $kioskBlue,
  'red': $kioskRed,
  'white': $white,
  'black': $black,
);

@mixin disabledMixin($fill, $color) {
  &.disabled {
    fill: $fill;
    border: 2px solid $fill;
    box-shadow: 0 13px 0 -8px $fill;
    color: $color;
    cursor: not-allowed;
  }
}

@mixin activeMixin($typographyColor) {
  &:active {
    &:not(.disabled):not(.loading) {
      transform: translateY(4px);
      box-shadow: none;

      .typography {
        color: $typographyColor;
      }
    }
  }
}

@mixin hoverMixin($color, $fill: true) {
  &:hover {
    .typography {
      color: $color;

      @if $fill == true {
        path,
        circle,
        line {
          fill: $color;
        }
      }
    }
  }
}

.button {
  display: flex;
  justify-content: center;
  padding: 12px 28px;
  background: transparent;
  color: $white;
  transition: all 0.2s;

  &.loading {
    cursor: wait;
  }

  .typography {
    transition: all 0.2s;
    height: 100%;
  }

  .content {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &.primary, &.secondary, &.social {
    @each $name, $color in $colors {
      &.#{$name} {
        border: 2px solid $color;
        box-shadow: 0 13px 0 -8px $color;

        &:active {
          &:not(.disabled):not(.loading) {
            border: 2px solid $color;
          }
        }

        &:focus {
          border: 2px solid $color;
        }
      }
    }
  }

  &.primary {
    @include activeMixin($darkGrey);
    @include hoverMixin($darkGrey, $darkGrey);
    @include disabledMixin($lightFadedBlue, $darkGrey);
  }

  &.secondary {
    background: $white;
    color: $black;
    @include activeMixin($darkGrey);
    @include hoverMixin($darkGrey, $darkGrey);
    @include disabledMixin($darkGrey, $darkGrey);
  }

  &.social {
    display: block;
    border-left: none;
    padding: 0;
    height: 44px;

    &:active {
      &:not(.disabled):not(.loading) {
        border-left: none;
      }
    }

    &:focus {
      border-left: none;
    }

    &:disabled {
      border-left: none;
    }

    @include activeMixin($white);
    @include hoverMixin($darkGrey, false);
    @include disabledMixin($lightFadedBlue, $darkGrey);
  }

  &.text {
    padding: 5px;
    background: transparent;
    color: inherit;
    border: 2px solid transparent;
    border-radius: $xsBorderRadius;

    @include hoverMixin($darkGrey, $darkGrey);
    @include disabledMixin($darkGrey, $darkGrey);

    &:active {
      &:not(.disabled):not(.loading) {
        .typography {
          color: $darkGrey;
        }
      }
    }

    @each $name, $color in $colors {
      &.#{$name} {
        color: $color;
      }
    }
  }
}
