@import '~@Styles/vars';

.container {
  background-color: $white;
  width: 700px;
  padding: 44px;
  color: $dustyDarkBlue;
  border-radius: $xsBorderRadius;
  text-align: left;

  h3 {
    margin-bottom: 1em;
  }

  p {
    padding: 1em 0;
  }

  ul {
    margin-left: 2em;

    li {
      list-style-type: disc;
      padding: 5px 0;
    }
  }
}
