.container {
  grid-template-columns: [logo] 100px [space] 16px [links] auto [space] 16px [settings] max-content;

  @media (max-width: 768px) {
    height: auto;
    grid-template-areas:
        ". . . . ."
        "links links links links links";
  }
}
