@import '~@Styles/vars';

.container {
  padding-bottom: 8px;

  .topContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .typography {
    color: $white;
    margin-bottom: 4px;
  }

  .toggleSwitchCheckbox {
    display: none;
  }

  .toggleSwitchLabel {
    width: 40px;
    min-width: 40px;
    height: 19px;
    padding: 3px;
    display: flex;
    border-radius: $borderRadius;
    border: 1px solid $lightFadedBlue;
    margin-bottom: 4px;
    margin-left: 4px;

    .toggleSwitchButton {
      width: 11px;
      height: 11px;
      background: $lightFadedBlue;
      border-radius: $borderRadiusCircle;
      transition: all 0.2s ease-in 0s;
    }
  }

  .toggleSwitchCheckbox:checked + .toggleSwitchLabel {
    .toggleSwitchButton {
      background: $white;
      transform: translateX(21px);
    }
  }
}
