@import '~@Styles/vars';
@import '~@Styles/mixins';

.container {
  flex: 1;
  display: flex;
}

.rightSide {
  flex: 1;
  position: relative;
}

.editorContainer {
  margin: 20px;
  flex: 1;
  display: flex;
}

.editor {
  margin: 0 auto;
}

.layoutSelectionOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $black;
  padding: 50px 150px;
  max-height: 100%;
  overflow: auto;
  @include scrollbar($lightGrey, $darkPaleBlue);
}

.layoutFilterInputContainer {
  display: grid;
  grid-template-columns: max-content auto;
  grid-column-gap: 8px;
  align-items: center;
  padding: 10px;
  margin-top: 40px;
  border: 1px solid $lightGrey;
  max-width: 300px;

  &:focus-within {
    border-color: $kioskPurple !important;
  }

  input {
    border: none;
    background: inherit;
    padding: 0;
    height: 20px;
    width: 100%;

    &::placeholder {
      color: $lightFadedBlue;
    }
  }
}

.layoutGroups {
  padding-top: 33px;
  display: grid;
  row-gap: 10px;
}

.layoutList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 -10px;
  column-gap: 10px;
  row-gap: 10px;
}

.layoutItem {
  position: relative;
  padding: 10px;
  border: 0;
  outline: 0;
  background: none;
  text-align: left;
  border-radius: $xsBorderRadius;
  transition: 300ms background-color;
  overflow: hidden;

  &:hover {
    background: $fadedBlue;
  }
}

.layoutName {
  margin-top: 4px;
}

.removeIcon {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 28px;
  height: 28px;
  background: $veryLightGrey;
  border-radius: $borderRadiusCircle;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: $lightGrey;
  }
}