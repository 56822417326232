@import '~@Styles/vars';

.show {
  display: grid;
  grid-template-columns: 120px auto max-content min-content;
  grid-column-gap: 20px;
  padding: 16px 16px 16px 0;
  align-items: center;

  &:not(:last-of-type) {
    border-bottom: 1px solid $fadedBlue;
  }

  &.showDragged {
    border-bottom: 0;
    opacity: 0.5;
  }

  .dropdown {
    align-content: center;
  }

  .showDuration {
    color: $transparentWhite;
  }
}

.noShowsMessage {
  padding: 16px 0;
}
