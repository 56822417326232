@import '~@Styles/vars';

.content {
  display: grid;
  grid-row-gap: 20px;
  @media (min-width: 1200px) {
    grid-template-columns: 400px;
  }

  .form {
    display: grid;
    row-gap: 20px;
  }
}


