@import '~@Styles/vars';

.container {
  .url {
    color: $kioskPurple;
  }
  .divider {
    margin-top: 20px;
  }
}
