@import '~@Styles/vars';

.container {
  display: grid;
  row-gap: 4px;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }

  .label {
    color: $lightFadedBlue;
  }

  .input {
    padding: 4px;
    border: 2px solid $kioskPurple;
    border-radius: $inputBorderRadius;
    background: none;
    height: unset;
    max-width: calc(100vw - 290px - 80px - 100px);
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
    }
  }

  .display {
    margin-top: 4px;
  }
}
