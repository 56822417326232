@import '~@Styles/vars';

.container {
  z-index: $zindex-modal;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  top: 0;
  width: 100vw;
  height: 100%;
  animation: fadeIn .35s;

  .backdrop {
    position: absolute;
    background-color: $black;
    opacity: 0.7;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 100vw;
    height: 100vh;
    z-index: $zindex-negative;
  }
}

