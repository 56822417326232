@import '~@Styles/vars';
@import '~@Styles/mixins';

.addToButton {
  display: grid;
  grid-template-columns: max-content;
  grid-column-gap: 12px;
  align-items: center;

  &.selectedChannel {
    grid-template-columns: max-content max-content;
  }
}

.dropdownContent {
  max-height: 200px;
  overflow: auto;
  position: relative;
  @include scrollbar($lightGrey, $white);
}

.loadingIconContainer,
.noOptionMessage,
.tabDropdownItem {
  padding: 14px 0 0 0;
}

.emoji {
  height: 20px;
  width: 20px;

  .image {
    height: 20px;
    width: 20px;
  }
}

.contentDropdownItem {
  padding: 8px 8px 8px 20px;
}

.tabList {
  display: flex;
  align-items: center;
  min-width: 200px;
}

.tab {
  cursor: pointer;
  margin-right: 8px;
  text-transform: uppercase !important;
}

.tabContent {
  max-height: 200px;
  overflow: auto;
  position: relative;
  @include scrollbar($lightGrey, $white);
}