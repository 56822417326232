@import '~@Styles/vars';
@import '~@Styles/mixins';

.slideContainer {
  padding: 20px;
  border-bottom: 1px solid $fadedBlue;
  cursor: pointer;
  position: relative;

  .slidePreview {
    height: 135px;
  }

  .slideHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 14px;
  }

  .contentBox {
    display: none;
  }

  &.activeSlide {
    .slidePreview {
      box-shadow: 0 0 0 2px $kioskPurple;
    }

    &.slideEditVisible {
      .slideHeader {
        padding-bottom: 12px;
      }

      .contentBox {
        display: grid;
        row-gap: 16px;
        background: $darkPaleBlue;
        padding-right: 0;
      }

      .field {
        row-gap: 4px;
        padding-right: 20px;

        label {
          color: $lightFadedBlue;
        }

        input {
          padding: 6px !important;
          border-color: $fadedBlue;
        }

        .description {
          color: $lightFadedBlue;
        }
      }
    }
  }
}
