@import '~@Styles/vars';

.container {
  height: 42px;
  width: 42px;
  border-radius: $borderRadiusCircle;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .emojiImage {
    width: 24px;
    height: 24px;
  }
}
