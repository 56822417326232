@import '~@Styles/vars';
@import '~@Styles/mixins';

.dropdownContent {
  max-height: 200px;
  overflow: auto;
  position: relative;
  @include scrollbar($lightGrey, $white);
}

.savingOverlay {
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: $zindex-positive;
    background-color: $transparentWhite;
  }
}

.emoji {
  height: 20px;
  width: 20px;

  .image {
    height: 20px;
    width: 20px;
  }
}

.loadingIconContainer,
.noOptionMessage,
.tabDropdownItem {
  padding: 14px 0 0 0;
}

.contentDropdownItem {
  padding: 8px 8px 8px 20px;
}

.tabList {
  display: flex;
  align-items: center;
  min-width: 200px;
}

.tab {
  cursor: pointer;
  margin-right: 8px;
  text-transform: uppercase !important;
}

.tabContent {
  max-height: 200px;
  overflow: auto;
  position: relative;
  @include scrollbar($lightGrey, $white);
}
