@import '~@Styles/vars';

.container {
  display: grid;
  align-items: center;
  justify-items: center;
  width: 200px;
  height: 200px;
  border: 1px solid $lightGrey;
  cursor: pointer;
  position: relative;

  &.highlighted {
    border: 2px solid $kioskPurple;
  }

  .preview {
    max-width: 100%;
    max-height: 100%;
    min-height: 0;
    padding: 1px;
  }

  .previewPlaceholder {
    width: 100%;
    height: 100%;

    .previewPlaceholderCircle {
      width: 122px;
      height: 122px;
    }
  }

  .removeIcon {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 28px;
    height: 28px;
    background: $veryLightGrey;
    border-radius: $borderRadiusCircle;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background-color: $lightGrey;
    }
  }
}
