@import '~@Styles/vars';

.container {
  display: grid;
  grid-template-columns: auto max-content;
  grid-auto-columns: max-content;
  grid-column-gap: 12px;
  align-items: center;

  &.withImage {
    grid-template-columns: max-content auto max-content;
  }

  &:hover {
    .optionText {
      color: $kioskPurple;
    }
  }

  .selectedText {
    color: $kioskPurple;
  }

  .icon {
    path {
      fill: $kioskPurple;
    }
  }
}