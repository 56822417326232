@import '~@Styles/vars';

.container {
  .title {
    margin-bottom: 16px;
  }

  .channel {
    margin: 20px 0;
    padding: 16px;
    cursor: pointer;
    border: 1px solid $lightFadedBlue;
  }

  .channelTopContainer {
    display: grid;
    grid-template-columns: auto min-content;
    padding-bottom: 16px;
  }

  .channelEmoji {
    margin-left: 10px;
    width: 30px;
    height: 30px;

    .channelEmojiImage {
      width: 18px;
      height: 18px;
    }
  }

  .channelName {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .channelShowPreviewContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 8px;
  }

  .loader {
    margin: 20px 0;
  }
}

