@import '~@Styles/vars';

.container {
  display: grid;
  width: 100%;
  height: 100%;
  grid-row-gap: 8px;
  grid-auto-rows: max-content;

  &:hover {
    cursor: pointer;
  }

  .info {
    display: grid;

    .showName {
      color: $lightFadedBlue;
    }
  }

  .showNamePlaceholder {
    margin-top: 2px;
    width: 60%;
    height: 22.4px;
  }
}
