@import '~@Styles/mixins';
@import '~@Styles/vars';

.container {
  max-height: 100%;
  border-right: 1px solid $fadedBlue;
  overflow-y: scroll;
  @include scrollbar($fadedBlue, $black);
  display: flex;
  min-width: 95px;
}

.content {
  padding: 12px 0 12px 12px;
  display: grid;
  grid-template-columns: max-content;
  margin: auto;
  grid-row-gap: 14px;
}

.iconContainer {
  display: grid;
  justify-items: center;
  grid-template-rows: max-content max-content;
  row-gap: 4px;
  text-transform: uppercase;
  color: $lightGrey;
  cursor: pointer;
  padding: 8px;
  border-radius: $mediumBorderRadius;
  transition: 300ms background-color;

  &:hover {
    background: $darkPaleBlue;
  }
}

.divider {
  border-bottom: 1px solid $fadedBlue;
}
