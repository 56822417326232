@import '~@Styles/vars';

.dropdown {
  position: relative;
  cursor: pointer;
  user-select: none;
  display: inline-flex;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  color: $black;
  background: $white;
  padding: 8px 0;
  border-radius: $xsBorderRadius;
  z-index: $zindex-dropdown;
  box-shadow: 0 0 4px 0 $lightFadedBlue;
}

.subDropdown {
  &:hover {
    >.subDropdownContent {
      display: block;
    }
  }

  .subDropdownContent {
    padding-left: 8px;
    position: absolute;
    top: 0;
    left: 100%;
    display: none;
  }
}
