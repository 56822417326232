@import '~@Styles/vars';

.container {
  display: grid;
  row-gap: 4px;
  padding-right: 20px;
}

.header {
  display: flex;
  align-items: center;
}

.label {
  color: $lightFadedBlue;
}

.connectButton {
  cursor: pointer;
}

.connectedAccount {
  display: flex;
  align-items: center;
}

.removeAccountIcon {
  margin-left: 6px;
  cursor: pointer;

  path {
    fill: $lightFadedBlue;
    stroke: $darkPaleBlue;
  }
}
