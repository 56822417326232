@import '~@Styles/vars';

.container {
  display: grid;
  row-gap: 4px;
  cursor: pointer;

  .header {
    display: flex;
    align-items: center;
  }

  .inputWrapper {
    border-color: $fadedBlue;
    position: relative;

    .protocol {
      position: absolute;
      top: 9px;
      left: 8px;
    }

    .input {
      cursor: pointer;
      background: transparent;
      padding-left: 56px !important;
      min-height: 20px;
      text-overflow: ellipsis;
      height: auto;
      line-height: 20px;
      min-width: 0;
      width: 100%;

      &:focus-within {
        border-color: $kioskPurple !important;
      }

      &:hover {
        color: $kioskPurple;
      }

      &.error {
        border-color: $kioskPurple;
      }
    }
  }
}
