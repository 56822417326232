@import '~@Styles/vars';

.field {
  display: grid;
  row-gap: 4px;
  padding-right: 20px;

  label {
    color: $lightFadedBlue;
  }

  input {
    padding: 6px !important;
    border-color: $fadedBlue;
  }

  .description {
    color: $lightFadedBlue;
  }
}
