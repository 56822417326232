@import '~@Styles/vars';

.changePasswordSuccessContainer {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-row-gap: 20px;
}

.button {
  justify-self: center;
  width: fit-content;
}
