@import '~@Styles/vars';

.avatar {
  display: grid;
  background: none;
  width: 36px;
  height: 36px;
  overflow: hidden;
  border-radius: $borderRadius;

  img {
    width: 100%;
    height: 100%;
  }
}

.avatarPlaceholder {
  width: 36px;
  height: 36px;
}
