@import '~@Styles/vars';
@import '~@Styles/mixins';

.grid {
  grid-template-columns: 293px 1fr;
  column-gap: 0;
  position: relative;
  overflow: hidden;
}

.form {
  display: contents;
}

.navigationFields {
  display: grid;
  grid-column: details;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: max-content max-content max-content;

  .leftNavigationSection {
    display: grid;
    align-items: center;
    grid-template-columns: 300px;
  }

  .rightNavigationSection {
    display: grid;
    grid-column-gap: 15px;
    align-items: center;
    grid-auto-flow: column;
  }
}

.leftSideContainer {
  border-right: 1px solid $fadedBlue;
  overflow-y: scroll;
  position: relative;
  @include scrollbar($fadedBlue, $black);

  .addSlideContainer {
    margin: 20px;
    position: relative;

    .addSlide {
      height: 140px;
      width: 100%;
      border: 1px solid $fadedBlue;
      background-color: transparent;
      color: $white;
    }
  }
}

.rightSideContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .noSlideButton {
    padding: 20px;
    background-color: transparent;
    color: $white;
    border: 0;

    .eyeIcon {
      margin-bottom: 20px;
    }
  }
}

.downloadIcon {
  margin-right: 14px;
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.3);
  }
  75% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

.scaleAnimation {
  animation: heartbeat 1s 1;
}

.logoContainer {
  width: 170px;
  align-self: flex-start;
  position: relative;
  top: -6px;

  $zindex: 1000;

  .logo {
    position: absolute;
    width: 100%;
    z-index: $zindex;
  }
}
