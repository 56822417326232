@import '~@Styles/vars';

.container {
  border: 1px solid $darkPaleBlue;
  padding: 28px 0;
  display: grid;
  grid-template-rows: auto min-content min-content;
  align-items: flex-start;
  cursor: pointer;

  &:hover {
    border: 1px solid lighten($darkPaleBlue, 10%);
  }

  .topContainer {
    display: grid;
    grid-template-columns: max-content auto;
    grid-row-gap: 8px;
    padding: 0 28px 14px 28px;

    .emoji {
      margin-right: 12px;
    }

    .channelName {
      cursor: pointer;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .controls {
    display: flex;
    margin-left: auto;
    align-items: center;

    .previewIcon {
      display: flex;
      padding: 2.5px;
      cursor: pointer;
    }

    .moreIcon {
      margin-left: 7px;
      display: flex;
      padding: 2.5px;
    }
  }

  .showPreviewContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 8px;
    padding: 0 28px 22px 28px;
  }

  .showsHeader {
    padding: 8px 20px 8px 28px;
    border-bottom: 1px solid $darkPaleBlue;
    display: flex;
    justify-content: space-between;
  }

  .showsContainer {
    min-height: 155px;

    .showRow {
      padding: 12px 20px 12px 0;
      margin-left: 28px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $lightFadedBlue;

      &:not(:first-of-type) {
        border-top: 1px solid $darkPaleBlue;
      }
    }

    .remainingShowsText {
      padding: 4px 28px 0 0;
      margin-left: 28px;
    }
  }
}

.containerPlaceholder {
  height: 319px;

  .namePlaceholder {
    width: 50%;
    height: 42px;
  }

  .emojiPlaceholder {
    width: 42px;
    height: 42px;
  }
}
