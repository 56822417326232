@import '~@Styles/vars';

.container {
  .topContainer {
    padding-bottom: 20px;
    display: flex;
    align-items: center;
  }

  .closeIcon {
    cursor: pointer;
    margin-left: auto;

    path {
      stroke: $lightFadedBlue;
    }

    &:hover {
      path {
        stroke: $white;
      }
    }
  }

  .title {
    margin-bottom: 16px;
  }

  .overscanViewOption {
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 24px auto;
    height: 180px;
    width: 327px;
    border: 1px solid $lightFadedBlue;
  }

  .overscanViewOptionWithBorder {
    border: 5px solid $kioskRed;
  }
}
