@import '~@Styles/vars';

.container {
  background-color: $white;
  width: 600px;
  padding: 44px;
  color: $dustyDarkBlue;
  border-radius: $xsBorderRadius;
  text-align: left;

  .title {
    margin-bottom: 8px;
  }

  .infoText {
    margin-bottom: 28px;
  }

  .emailInput {
    margin-bottom: 20px;

    label {
      color: $darkGrey;
    }

    input {
      background-color: $white;
      color: $black;
      width: 400px;
    }
  }

  .roleInputContainer {
    margin-bottom: 20px;
    width: 400px;

    label {
      color: $darkGrey;
    }

    .roleDescription {
      color: $lightFadedBlue
    }
  }

}
