@import '~@Styles/vars';

.container {
  position: relative;
  width: 100%;

  .saturation {
    position: relative;
    width: 100%;
    height: 150px;
    margin-bottom: 10px;
  }

  .bottomContainer {
    display: flex;

    .preview {
      height: 16px;
      width: 16px;
      border-radius: $xsBorderRadius;
      margin-right: 16px;
    }

    .transparent {
      height: 18px;
      width: 18px;
      min-width: 18px;
      border-radius: $xsBorderRadius;
      margin-right: 16px;
      position: relative;
      border: 1px solid transparent;

      &.transparentActive {
        border-color: $kioskRed;
      }
    }

    .hue {
      position: relative;
      width: 100%;
      height: 16px;
    }
  }
}
