@import '~@Styles/vars';

.logoHeaderContainer {
  display: grid;
  grid-row-gap: 20px;
  justify-content: center;

  .typography {
    color: $lightFadedBlue;
    text-align: center;
  }
}
