@import '~@Styles/vars';

.container {
  display: grid;
  align-items: center;
  justify-items: center;
  width: 200px;
  height: 200px;
  border: 1px solid $lightGrey;
  position: relative;
  cursor: wait;

  .preview {
    max-width: 100%;
    max-height: 100%;
    padding: 1px;
  }

  .overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    .backdrop {
      background-color: $white;
      opacity: 0.9;
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .content {
      position: relative;
      display: grid;
      grid-row-gap: 10px;
      align-content: center;
      justify-items: center;
      height: 100%;
      color: $black;

      path {
        fill: $black;
      }
    }
  }
}
