@import '~@Styles/vars';

.container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.link {
  color: $kioskPurple;
  margin-right: 4px;

  &:hover {
    color: $hoverPurple;
  }
}

.text {
  margin-right: 4px;
}
