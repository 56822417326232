@import '~@Styles/vars';

.container {
  grid-column: col-start 1 / col-end 18;
  padding: 20px 0;

  .topContainer {
    display: flex;
    justify-content: space-between;

    .emoji {
      display: inline-block;
      margin-bottom: 2px;
    }

    .field {
      input {
        border: 2px solid transparent;
        background: none;
        padding: 4px;
        margin-left: -7px;
        color: $white;
        height: 100%;
        font-family: $interFontFamily;
        font-style: $fontStyle;
        font-weight: $bold;
        font-size: $xlargeFontSize;
        line-height: $xLineHeight;
        border-radius: $inputBorderRadius;

        &::placeholder {
          color: $darkPaleBlue;
        }

        &:focus {
          border-color: $kioskPurple;
        }
      }
    }

    .topControls {
      display: flex;
      align-items: center;
      margin: 52px 0 47px 10px;
      padding-bottom: 5px;

      .addShowButton {
        margin-right: 20px;
      }
    }
  }

  .showsContainer {
    background-color: $darkPaleBlue;
    padding: 4px 0 4px 20px;
  }
}

.navigationFields {
  display: grid;
  grid-column: details;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: max-content max-content;

  .leftNavigationSection {
    display: grid;
    align-items: center;
    grid-column-gap: 15px;
    grid-template-columns: max-content max-content;
  }

  .rightNavigationSection {
    display: grid;
    grid-column-gap: 15px;
    align-items: center;
    grid-auto-flow: column;
  }
}

.bottomContainer {
  border-top: 1px solid $fadedBlue;
  padding: 20px;
  display: grid;
  grid-template-columns: max-content;
  align-items: center;

  .channelDuration {
    display: grid;
    column-gap: 12px;
    grid-template-columns: max-content max-content;
  }
}

.shareIcon,
.previewIcon {
  margin-right: 14px;
}