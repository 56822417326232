@import '~@Styles/vars';

.content {
  display: grid;
  grid-row-gap: 20px;
}

.topContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.closeIcon {
  cursor: pointer;

  path {
    stroke: $lightFadedBlue;
  }

  &:hover {
    path {
      stroke: $white;
    }
  }
}

.screenshotImage {
  width: 100%;
}
