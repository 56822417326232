@import '~@Styles/vars';
@import '~@Styles/mixins';

.grid {
  display: grid;
  column-gap: 20px;
  overflow-y: scroll;
  @include scrollbar($lightGrey, $black);
}

@media screen and (max-width: 1440px) {
  .grid {
    grid-template-columns: [margin-left-start] 80px [margin-left-end] repeat(18, [col-start] 1fr [col-end]) [margin-right-start] 80px [margin-right-end];
  }
}
@media screen and (min-width: 1440px) {
  .grid {
    grid-template-columns: [margin-left-start] 1fr [margin-left-end] repeat(18, [col-start] 50px [col-end]) [margin-right-start] 1fr [margin-right-end];
  }
}
