@import '~@Styles/vars';

.container {
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }

  .title {
    margin-bottom: 16px;
  }

  .nameTvViewInputContainer {
    margin: 32px 0;
  }

  .nameTvViewInput {
    padding: 0;
    color: $white !important;
    border: 0;
    caret-color: $white;

    font-family: $interFontFamily;
    font-style: $fontStyle;
    font-weight: $bold;
    font-size: $xlargeFontSize;
    line-height: 45px;
  }

  .nextAction {
    margin-top: 20px;

    @media (max-width: 768px) {
      margin-top: auto;
    }
  }
}
