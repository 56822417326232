@import '~@Styles/vars';
@import '~@Styles/mixins';

.container {
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }

  .title {
    margin-bottom: 16px;
  }

  .tvImage {
    padding: 12px;
    text-align: center;
    cursor: pointer;
    position: relative;

    svg {
      max-width: 100%;
    }

    &.selected {
      path, rect {
        stroke: $kioskGreen;
      }
    }
  }

  .tvImageTextContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 140px;
    display: grid;
    grid-row-gap: 4px;
  }

  .nextAction {
    margin-top: 20px;

    @media (max-width: 768px) {
      margin-top: auto;
    }
  }
}

