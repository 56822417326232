@import '~@Styles/vars';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  .bgImage {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .image {
    border-radius: $borderRadiusCircle;
    width: 272px;
    height: 272px;
    margin-bottom: 40px;
  }

  .headline {
    margin-bottom: 15px;
  }
}

