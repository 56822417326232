@import '~@Styles/vars';

.container {
  display: grid;
  row-gap: 4px;

  .emptyInput {
    cursor: pointer;
    min-height: 20px;
    background: transparent;
    border: 0;
    color: $white;
    text-align: left;
  }

  .input {
    position: relative;
    cursor: pointer;
    min-height: 20px;
    background: transparent;
    border: 1px solid $fadedBlue;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    .image {
      max-height: 150px;
      max-width: 100%;
    }
  }

  .editBadge {
    position: absolute;
    right: 0;
    top: 0;
    background-color: $fadedBlue;
    color: $white;
    margin: 16px;
    padding: 2px 6px;
    text-transform: uppercase;
    border-radius: $xsBorderRadius;
  }
}
