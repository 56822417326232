@import '~@Styles/vars';
@import '~@Styles/mixins';

.container {
  display: grid;
  width: 100%;
  height: 100%;
  grid-row-gap: 8px;
  grid-template-rows: auto max-content;

  &:hover {
    cursor: pointer;
  }

  .infoContainer {
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
    grid-column-gap: 8px;

    .showInfoContainer {
      overflow: hidden;

      .showName {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .showNamePlaceholder {
      margin-top: 2px;
      width: 60%;
      height: 22.4px;
    }
  }
}

.controls {
  display: flex;
  align-items: center;
  margin-left: auto;

  .previewIcon {
    display: flex;
    padding: 2.5px;
    cursor: pointer;
  }

  .moreIcon {
    margin-left: 7px;
    display: flex;
    padding: 2.5px;
  }
}

.dropdownContent {
  max-height: 200px;
  overflow: auto;
  position: relative;
  @include scrollbar($lightGrey, $white);

  &.savingOverlay {
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: $zindex-positive;
      background-color: $transparentWhite;
    }
  }
}

.emoji {
  height: 20px;
  width: 20px;

  .image {
    height: 20px;
    width: 20px;
  }
}
