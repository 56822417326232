.item {
  display: grid;
  align-items: center;
  padding: 12px;
  grid-template-columns: 36px 1fr;
  grid-column-gap: 12px;
}

.avatarPlaceholder {
  height: 36px;
}

.infoPlaceholder {
  height: 36px;
}