@import '~@Styles/vars';

.sidebar {
  grid-column: margin-left-start/col-end 3;
}

.content {
  display: grid;
  padding: 40px 0 40px 20px;
  grid-auto-rows: max-content;
  grid-column: col-start 4 / col-end 18;
}
