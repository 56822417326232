@import '~@Styles/vars';

.textInput {
  display: grid;
  row-gap: 8px;
  font-size: $regularFontSize;
  cursor: pointer;
}

.inputContainer {
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  grid-template-columns: auto max-content;
  border: 1px solid $lightGrey;

  input {
    border: none;
  }

  .forgotPassword {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 8px;
    color: $transparentWhite;
    &:hover {
      color: $kioskPurple;
      .text {
        font-weight: $bold;
      }
    }
  }
}
