@import '~@Styles/vars';

.container {
  display: flex;
  flex-direction: column;
  border-right: 1px solid $darkPaleBlue;

  .item {
    padding: 24px 32px;
    border-bottom: 1px solid $darkPaleBlue;

    @media (max-width: 768px) {
      padding: 18px 12px;
    }
  }

  .subItem {
    display: block;
    margin-top: 16px;
  }
}
