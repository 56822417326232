@import '~@Styles/vars';

@mixin textOverflow() {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@mixin scrollbar($thumb-color, $background-color) {
  scrollbar-width: thin;
  scrollbar-color: $thumb-color $background-color;

  &::-webkit-scrollbar {
    width: 12px;
    height: 12px; //horizontal scrollbar size.
  }

  &::-webkit-scrollbar-thumb {
    background: $thumb-color;
    border-radius: $borderRadius;
    border: 4px solid $background-color;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
    border-radius: $xsBorderRadius;
  }
}

@mixin transition-with-delay($count, $type, $transitionTime: 0.3s, $initialDelay: 0) {
  @for $i from 1 through $count {
    &:nth-child(#{$i}) {
      transition: $type $transitionTime #{strip-unit($initialDelay) + ($i * 0.1)}s;
    }
  }
}

@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}
