@import '~@Styles/vars';

.noItemsMessage {
  color: $lightFadedBlue;
  grid-column: 1/-1;
}

.item {
  overflow: hidden;

  &:hover {
    .bottomSection {
      background-color: $black;
      opacity: 0.7;
      transform: translateY(0);
    }
  }

  .bottomSection {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 4px 8px;
    background-color: transparent;
    opacity: 0;
    transition: 300ms all;
    transform: translateY(30px);
  }

  .author {
    text-transform: initial;
  }
}
