@import '~@Styles/vars';
@import '~@Styles/mixins';

$slideListWidth: 292px;
$topBarHeight: 60px;
$horizontalMargin: 40px;
$canvasLeftToolsWidth: 95px;
$canvasTopEditorHeight: 60px;
$footerHeight: 60px;

.container {
  flex: 1;
  display: flex;
  width: calc(100vw - #{$slideListWidth});
  height: calc(100vh - #{$topBarHeight});

  .editorContainer {
    flex: 1;
    display: flex;
    align-items: center;
    margin: 0 20px;
    width: calc(100vw - #{$slideListWidth} - #{$horizontalMargin});
    height: calc(100vh - #{$topBarHeight});
  }

  &.isEditable {
    .editorContainer {
      width: calc(100vw - #{$slideListWidth} - #{$horizontalMargin} - #{$canvasLeftToolsWidth});
      height: calc(100vh - #{$topBarHeight} - #{$canvasTopEditorHeight});
    }
  }

  &.isEditable.footerPresent {
    height: calc(100vh - #{$topBarHeight} - #{$footerHeight});

    .editorContainer {
      height: calc(100vh - #{$topBarHeight} - #{$footerHeight} - #{$canvasTopEditorHeight});
    }
  }
}

.rightSide {
  flex: 1;
  position: relative;
  width: 100%;
}

.editor {
  margin: 0 auto;
}
