@import '~@Styles/vars';
@import '~@Styles/mixins';

.container {
  background-color: $white;
  color: $dustyDarkBlue;
  border-radius: $xsBorderRadius;
  display: inline-grid;
  text-align: left;
  margin: 0 auto;
  position: relative;
  width: 960px;

  .topContainer {
    padding: 44px 54px 20px 44px;
    display: grid;
    grid-template-columns: max-content max-content;
    grid-column-gap: 20px;
    align-items: center;
  }

  .inputContainer {
    display: grid;
    align-items: center;
    grid-auto-flow: column;
    grid-template-columns: auto max-content max-content;
    border: 1px solid $lightGrey;
    margin: 0 54px 14px 44px;
    padding: 10px;
    cursor: pointer;

    input {
      background-color: $white;
      color: $black;
      border: none;
      height: auto;
      padding: 0;
    }
  }

  .headlineContainer {
    margin: 0 54px 14px 44px;
    color: $black;
  }

  .listContainer {
    padding: 0 44px;
    overflow-y: scroll;
    height: 460px;
    position: relative;
    @include scrollbar($lightGrey, $white);

    .list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      margin-bottom: 40px;
    }

    .dragOverlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: $white;
      z-index: $zindex-positive;
      display: flex;
      justify-content: center;

      .dragContent {
        display: grid;
        grid-row-gap: 8px;
        grid-template-rows: max-content max-content;
        align-content: center;
        justify-content: center;
        text-align: center;
        border: 1px solid $lightGrey;
        border-radius: $borderRadiusCircle;
        width: 400px;
        height: 400px;

        .dragAndDropTitle {
          color: $black;
        }

        .dragAndDropText {
          color: $darkGrey;
        }
      }
    }
  }

  .giphyUploadingOverlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: $zindex-positive;

    .giphyUploadingBackdrop {
      background-color: $white;
      opacity: 0.9;
      position: absolute;
      width: 100%;
      height: 100%;
      cursor: wait;
    }

    .giphyUploadingContent {
      position: relative;
      display: grid;
      grid-row-gap: 10px;
      align-content: center;
      justify-items: center;
      height: 100%;
      color: $black;

      path {
        fill: $black;
      }
    }
  }
}

