@import '~@Styles/vars';

.iframeContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
}

.iframe {
  border: 0;
}

.noShowsMessage {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.actionsContainer {
  position: absolute;
  top: 12px;
  right: 12px;
  display: flex;
  height: 57px;
}

.horizontalIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 68px;
  cursor: pointer;
  background: $black;
  border-right: 1px solid $darkPaleBlue;
  border-top-left-radius: $mediumBorderRadius;
  border-bottom-left-radius: $mediumBorderRadius;

  .horizontalIcon {
    width: 36px;
    height: 19px;
    border: 1px solid $lightFadedBlue;
  }

  &.active {
    .horizontalIcon {
      border-color: $kioskGreen;
    }
  }
}

.verticalIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  cursor: pointer;
  background: $black;
  border-right: 1px solid $darkPaleBlue;

  .verticalIcon {
    width: 19px;
    height: 36px;
    border: 1px solid $lightFadedBlue;
  }

  &.active {
    .verticalIcon {
      border-color: $kioskGreen;
    }
  }
}

.closeIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  cursor: pointer;
  background: $black;
  border-top-right-radius: $mediumBorderRadius;
  border-bottom-right-radius: $mediumBorderRadius;

  &:hover {
    path {
      stroke: $darkGrey;
    }
  }
}

