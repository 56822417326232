@import '~@Styles/vars';

.container {
  background-color: $white;
  color: $dustyDarkBlue;
  padding: 44px;
  border-radius: $xsBorderRadius;
  width: 584px;
  text-align: left;
  margin: 0 auto;

  .controls {
    display: flex;
    align-items: center;
    margin-top: 20px;

    .confirmButton {
      margin-right: 28px;
    }
  }
}
