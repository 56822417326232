@import '~@Styles/vars';

.container {
  position: relative;
  width: 100%;
  height: 100%;

  .iframe {
    border: 0;
    left: 50%;
    position: absolute;
    top: 50%;
    width: 1920px;
    height: 1080px;
    background-color: $fadedBlue;
  }

  .iframeBlocker {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
