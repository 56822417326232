@import '~@Styles/vars';

.toastContainer {
  min-width: 360px;
  padding: 0 !important;

  &--top-right {
    top: 28px !important;
    right: 28px !important;
  }

  &--bottom-center {
    margin-left: -180px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .toast {
    color: $black;
    padding: 20px 16px 20px 24px;
    grid-auto-flow: column;
    align-items: center;
    display: grid;
    column-gap: 24px;
  }

  .toastError {
    background: $kioskRed;

    .toastErrorBody {
      display: grid;
      row-gap: 4px;
    }
  }

  .toastInfo {
    width: fit-content;
    background: $kioskBlue;
    color: $white;
    display: flex;
    padding: 16px 24px;
    margin-left: auto;
    margin-right: auto;
    min-height: 52px;
    box-shadow: none;
    border-radius: $xsBorderRadius;
  }

  .undoContent {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    grid-column-gap: 24px;
  }
}
