@import '~@Styles/vars';
@import '~@Styles/mixins';

.container {
  position: absolute;
  left: 0;
  top: 10px;
  background: $darkPaleBlue;
  border-radius: $mediumBorderRadius;
}

.title {
  padding: 28px 32px;
}

.listContainer {
  margin: 0 8px 0 0;
  overflow-y: scroll;
  max-height: calc(100vh - 60px - 60px - 10px - 10px - 80px); //menu bar + bottom bar + paddings + title height
  padding-top: 3px;
  @include scrollbar($lightGrey, $darkPaleBlue);
}

.list {
  margin: 0 14px 32px 32px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-row-gap: 28px;
  grid-column-gap: 28px;
}

.savingOverlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: $zindex-positive;
  background-color: $transparentWhite;
  border-radius: $mediumBorderRadius;
}
