@import '~@Styles/vars';

.container {
  border: 1px solid $darkPaleBlue;
  padding: 20px 0 20px;
  display: grid;
  grid-row-gap: 20px;

  .searchContainer {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: auto min-content;
    grid-column-gap: 8px;
    align-items: center;
    padding: 0 28px;

    .searchInput {
      border: none;
      background: inherit;
      padding: 0;
      height: 20px;

      &::placeholder {
        color: $lightFadedBlue;
      }
    }
  }

  .filter {
    border-top: 1px solid $darkPaleBlue;
    padding: 20px 28px 0;
  }
}
