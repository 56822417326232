@import '~@Styles/vars';

.container {
  height: 100%;
  display: grid;
  align-items: center;
  background: $black;

  .wrapper {
    display: grid;
    row-gap: 28px;

    .content {
      display: grid;
      align-content: center;
      justify-content: center;
      grid-row-gap: 48px;
      padding-top: 20px;
    }

    .footer {
      display: grid;
      justify-content: center;
      padding-bottom: 20px;
    }
  }
}
