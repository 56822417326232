@import '~@Styles/vars';

.container {
  display: grid;
  grid-column: logo;

  .logo {
    width: 100px;
    height: 20px;
  }
}
