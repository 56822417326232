@import '~@Styles/vars';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.alert {
  display: grid;
  grid-row-gap: 8px;
  padding: 20px;
  border: 3px solid $kioskRed;
  border-radius: $xsBorderRadius;
  max-width: 600px;
}