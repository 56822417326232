@import '~@Styles/vars';

.container {
  display: grid;
  row-gap: 4px;
  cursor: pointer;

  .input {
    cursor: pointer;
    background: transparent;
    padding: 10px;
    min-height: 20px;
    text-overflow: ellipsis;
    height: auto;
    line-height: 20px;
    min-width: 0;

    &:focus-within {
      border-color: $kioskPurple !important;
    }

    &:hover {
      color: $kioskPurple;
    }

    &.error {
      border-color: $kioskPurple;
    }
  }

  .header {
    display: flex;
    align-items: center;
  }
}
