.container {
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }
}
