@import '~@Styles/vars';

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: $black;

  .image {
    width: 100px;
    height: 100px;
  }
}
