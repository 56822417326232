@import '~@Styles/vars';
@import '~@Styles/mixins';

$scrollbarWidth: 18px;
$emojiSize: 24px;
$emojiesInSingleRow: 10;
$dropdownPadding: 20px;

.dropdownContent {
  display: grid;
  padding: 0 !important;
  justify-items: center;
  width: calc(#{$emojiesInSingleRow} * #{$emojiSize} + (#{$emojiesInSingleRow} - 1) * 2px + #{$scrollbarWidth} + #{$dropdownPadding} * 2);

  .topContainer {
    padding: $dropdownPadding $dropdownPadding 16px $dropdownPadding;
  }

  .emojiContainer {
    justify-self: stretch;
    margin: 0 8px 0 $dropdownPadding;
    max-height: 230px;
    overflow: auto;
    @include scrollbar($lightGrey, $white);

    .emojiList {
      display: grid;
      grid-template-columns: repeat(10, 1fr);
      grid-column-gap: 2px;
      grid-row-gap: 2px;
      margin-bottom: $dropdownPadding;

      .emoji {
        padding: 1px;
        cursor: pointer;
        display: flex;
        width: $emojiSize;
        height: $emojiSize;
      }
    }
  }

  .colourContainer {
    width: 100%;
    padding: 0 $dropdownPadding $dropdownPadding $dropdownPadding;
  }
}
