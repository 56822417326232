@import '~@Styles/vars';
@import '~@Styles/mixins';

.container {
  background-color: $darkPaleBlue;
  color: $white;
  padding: 23px 12px 23px 23px;
  border-radius: $mediumBorderRadius;
  width: 584px;
  text-align: left;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 50px);

  @media (max-width: 768px) {
    max-height: none;
    height: 100%;
    border-radius: $noBorderRadius;
  }
}

.innerContainer {
  padding-right: 11px;
  position: relative;
  max-height: 100%;
  overflow-y: auto;
  @include scrollbar($lightFadedBlue, $darkPaleBlue);
}
