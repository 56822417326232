/* --------------
	COLORS 🎨
---------------- */
// Theme colors
$kioskPurple: rgb(224, 137, 255);
$kioskGreen: rgb(88, 228, 161);
$kioskBlue: #34c2ff;
$kioskRed: #f65858;
$kioskTransparentPurple: rgba($kioskPurple, 0.1);
$kioskTransparentGreen: rgba($kioskGreen, 0.5);
$kioskTransparentBlue: rgba($kioskBlue, 0.07);
$hoverPurple: darken($kioskPurple, 8%);

// Dark colors
$black: #04091f;
$dustyDarkBlue: #111628;
$darkPaleBlue: #282c40;
$fadedBlue: #414762;

// White grey colors
$darkGrey: #81848f;
$lightFadedBlue: #717793;
$lightGrey: #d6d9e5;
$veryLightGrey: #f3f4f6;
$white: rgb(255, 255, 255);
$transparentWhite: rgba($white, 0.5);

/* --------------
	TEXT 🖨
---------------- */
$interFontFamily: 'Inter';
$ptMonoFontFamily: 'PT Mono';

$fontStyle: normal;

$xxLargeFontSize: 64px;
$xlargeFontSize: 37px;
$largeFontSize: 24px;
$mediumFontSize: 19px;
$regularFontSize: 14px;
$smallFontSize: 13px;
$xSmallFontSize: 11px;
$xxSmallFontSize: 10px;

$fontSize-69: 69px;
$fontSize-49: 49px;
$fontSize-26: 26px;
$fontSize-16: 16px;

$thinner: 200;
$thin: 300;
$normal: 400;
$regular: 500;
$bold: 700;
$bolder: 800;

$xxLineHeight: 105%;
$xLineHeight: 105%;
$largeLineHeight: 120%;
$mediumLineHeight: 23px;
$regularLineHeight: 19px;
$smallLineHeight: 16px;
$xSmallLineHeight: 14px;
$bodyLineHeight: 140%;
$lowerCaseLinkLineHeight: 140%;
$tinyLinkLineHeight: 128.1%;
$mobileHeadlineLineHeight: 122%;

/* --------------
	OTHER 🖨
---------------- */

$borderRadius: 18px;
$mediumBorderRadius: 12px;
$xsBorderRadius: 2px;
$noBorderRadius: 0;
$borderRadiusCircle: 50%;
$inputBorderRadius: 10px;
$selectBorderRadius: 60px;
$avatarBorderRadius: 22px;
$xlBorderRadius: 224px;

$zindex-negative: -1;
$zindex-positive: 1;
$zindex-dropdown: 5;
$zindex-modal: 10;

$dropDownMaxHeight: 360px;

$minWidth: 1200px;
