@import '~@Styles/vars';

.container {
  display: flex;
  align-items: center;
  outline: 1px solid $kioskPurple;

  .option {
    background-color: transparent;
    color: $kioskPurple;
    border: 1px solid $kioskPurple;
    padding: 6px 14px;
    cursor: pointer;

    &.active {
      background-color: $kioskPurple;
      color: $white;
    }
  }
}
