@import '~@Styles/vars';
@import '~@Styles/mixins';

$zIndex: 1000;
.actionBlocker {
  display:block;
  background: transparent;
  width: 100%;
  height: 100%;
  z-index : $zIndex;
  position: absolute;
  top:0;
  left:0;


  .messageWrapper {
    width: 100%;
    height: 100%;
    $transparentBlack:rgba($black, 0.5);
    background-color: $transparentBlack;
    opacity:0;
    transition: all 200ms ease-in-out;
    position: absolute;
    user-select: none;
  }

  .actionBlockerMessage {
    position: absolute;
    text-align: center;
    width: 90%;
    max-width: 300px;
    padding: 30px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }


  &:hover {
    .messageWrapper {
      opacity: 1;
    }
  }

}