@import '~@Styles/vars';

.header {
  padding-bottom: 32px;
}

.members {
  padding-top: 24px;
  color: $lightFadedBlue;
}

.users {
  display: grid;
  padding-top: 8px;
}
