@import '~@Styles/vars';

.container {
  display: grid;
  width: 100%;
  height: 100%;
  grid-row-gap: 8px;
  grid-auto-rows: max-content;

  &:hover {
    cursor: pointer;
  }

  .preview {
    overflow: hidden;
    // Calculated from the aspect ration of the content (in case of 16:9 it is 9/16= 0.5625)
    padding-top: 56.25%;
    position: relative;
    min-width: 185px;
    width: 100%;
  }

  .previewImage {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;

    .previewPlaceholderCircle {
      width: 40%;
      padding-top: 40%;
    }
  }

  .infoContainer {
    .namePlaceholder {
      margin-top: 2px;
      width: 60%;
      height: 22.4px;
    }
  }

  .hidden {
    display: none;
  }

  .iframe {
    left: 0;
    position: absolute;
    top: 0;
    width: 1920px;
    height: 1080px;
    transform-origin: 0 0;
    pointer-events: none;
    background-color: $white;
  }

  .livePreviewBadge {
    position: absolute;
    right: 0;
    top: 0;
    background-color: $kioskRed;
    color: $white;
    margin: 12px;
    padding: 2px 6px;
    text-transform: uppercase;
    border-radius: $xsBorderRadius;
  }

  .iframeLoading {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $darkPaleBlue;
  }
}
